import React, { Component } from "react";
import { Helmet } from "react-helmet";
import Layout from "../layout";
import TinyPhoto from "../components/TinyPhoto/TinyPhoto";
import config from "../../data/SiteConfig";
import KonmariTOC from "./konmari-toc";
import "./konmari.scss";

class KonmariOverview extends Component {
  render() {
    const pageName = "Overview";

    return (
      <Layout>
        <div className="singlePage">
          <Helmet title={`${pageName} ♡ ${config.siteTitle}`} />
          <div>
            <h1>KonMari</h1>
            <KonmariTOC />
            <h1>{pageName}</h1>
            <TinyPhoto src="/img/konmari/board_small.png" alt="" float="center" linked />

            <p>Hello <img src="/emoji/heart8.gif" alt="" /> This group of pages will be my thoughts and notes from going through my first konmari journey. I hope that you can find it useful or otherwise entertaining!</p>
          </div>
        </div>
      </Layout>
    );
  }
}

export default KonmariOverview;
